<template>
  <v-card
    class="card-shadow border-radius-xl px-4 py-5"
    :class="$route.name == 'VrDefault' ? 'bg-gradient-default' : ''"
  >
    <div
      class="
        bg-gradient-info
        shadow-success
        border-radius-lg
        py-3
        pe-1
        mb-6
        mt-n12
      "
    >
      <div class="chart-area">
        <canvas
          :height="$route.name == 'VrDefault' ? '370' : '170'"
          :id="salesViewsID"
        >
        </canvas>
      </div>
    </div>
    <h6
      class="ms-2 mt-6 mb-0 text-h6 font-weight-bold"
      :class="$route.name == 'VrDefault' ? 'text-white' : 'text-typo'"
    >
      Comming Soon
    </h6>
  </v-card>
</template>
<script>
import Chart from "chart.js/auto";

export default {
  name: "card-yearly-sales",
  data: function () {
    return {
      salesViewsID: "websiteViews",
    };
  },
  computed: {
    invoices() {
      return this.$store.getters.invoices;
    },
    monthlyData() {
      var array = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

      this.invoices.forEach((element) => {
        if (element.state == "approved") {
          var date = new Date(element.create_time);
          var now = new Date();
          if (now.getFullYear() == date.getFullYear()) {
            array[date.getMonth()] += parseFloat(
              element.transactions[0].amount.total
            );
          }
        }
      });

      return array;
    },
  },
  watch: {
    invoices() {
      new Chart(document.getElementById(this.salesViewsID).getContext("2d"), {
        type: "bar",
        data: {
          labels: [
            "Jan",
            "Feb",
            "Mär",
            "Apr",
            "Mai",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Okt",
            "Nov",
            "Dez",
          ],
          datasets: [
            {
              label: "Einnahmen in €",
              tension: 0.4,
              borderWidth: 0,
              borderRadius: 4,
              borderSkipped: false,
              backgroundColor: "rgba(255, 255, 255, .8)",
              data: this.monthlyData,
              maxBarThickness: 6,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          interaction: {
            intersect: false,
            mode: "index",
          },
          scales: {
            y: {
              grid: {
                drawBorder: false,
                display: true,
                drawOnChartArea: true,
                drawTicks: false,
                borderDash: [5, 5],
                color: "rgba(255, 255, 255, .2)",
              },
              ticks: {
                display: true,
                color: "#f8f9fa",
                padding: 10,
                font: {
                  size: 14,
                  weight: 300,
                  family: "Roboto",
                  style: "normal",
                  lineHeight: 2,
                },
              },
            },
            x: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
                drawTicks: false,
                borderDash: [5, 5],
              },
              ticks: {
                display: true,
                color: "#f8f9fa",
                padding: 10,
                font: {
                  size: 14,
                  weight: 300,
                  family: "Roboto",
                  style: "normal",
                  lineHeight: 2,
                },
              },
            },
          },
        },
      });
    },
  },
  mounted() {
    // this.$store.dispatch("getAllInvoices");
  },
  methods: {
    renderLineChart: function() {

    }
  }
};
</script>
